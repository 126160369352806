<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left py-2>
        <span
          style="font-family: opensansbold; font-size: 14px; color: #808080"
        >
          Recent Orders
        </span>
      </v-flex>
      <v-flex xs12 py-2 v-for="(item, i) in storage" :key="i">
        <v-card flat rounded="xl" color="#F5F7FC" outlined>
          <v-layout px-4 wrap justify-center>
            <v-flex xs12>
              <v-layout wrap justify-center>
                <!-- <v-flex xs3 sm2 pa-2 align-self-center>
                  <v-img
                    :src="mediaURL + item.product.photos[0]"
                    height="80px"
                    contain
                    :alt="item.product.productname"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="#FF4343"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-flex> -->
                <v-flex py-4 xs12 sm12 text-left>
                  <router-link :to="'/orderDetails?id=' + item._id">
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <!-- <router-link
                            :to="'/Product/' + item.product._id"
                          > -->
                            <span
                              style="
                                font-family: opensansbold;
                                font-size: 16px;
                                color: #808080;
                              "
                            >
                              {{ item.userId.name }}
                              <!-- <span v-if="item.userId.name">
                                ...
                              </span> -->
                            </span>
                          <!-- </router-link> -->
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span
                            style="
                              font-family: opensansbold;
                              font-size: 14px;
                              color: #000000;
                            "
                          >
                          Phone: {{ item.userId.phone }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs4 sm2>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span
                            style="
                              font-family: opensansbold;
                              font-size: 16px;
                              color: #808080;
                            "
                          >
                           Total Price
                          </span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span
                            style="
                              font-family: opensansbold;
                              font-size: 14px;
                              color: #000000;
                            "
                          >
                            ₹{{ item.subTotal }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs4 sm2>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span
                            style="
                              font-family: opensansbold;
                              font-size: 16px;
                              color: #808080;
                            "
                          >
                           Coupon Discount
                          </span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span
                            style="
                              font-family: opensansbold;
                              font-size: 14px;
                              color: #000000;
                            "
                          >
                            ₹{{ item.couponDiscount }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs4 sm2>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span
                            style="
                              font-family: opensansbold;
                              font-size: 16px;
                              color: #808080;
                            "
                          >
                            Order Id
                          </span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span
                            style="
                              font-family: opensansbold;
                              font-size: 14px;
                              color: #000000;
                            "
                          >
                            {{ item.orderId }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs4 sm2>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span
                            style="
                              font-family: opensansbold;
                              font-size: 16px;
                              color: #808080;
                            "
                          >
                          Order Status
                          </span>
                        </v-flex>
                        <v-flex xs12 v-if="item.orderStatus=='Active'" text-left>
                          <span
                            style="
                              font-family: opensansbold;
                              font-size: 14px;
                              color: #000000;
                            "
                          >
                            Order Placed
                          </span>
                        </v-flex>
                        <v-flex xs12 v-else text-left>
                          <span
                            style="
                              font-family: opensansbold;
                              font-size: 14px;
                              color: #000000;
                            "
                          >
                            {{ item.orderStatus }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {};
  },
};
</script>