<template>
  <div>
    <v-layout wrap justify-start>
      <v-flex xs12 lg10 xl8>
        <v-dialog
          v-model="showQRCodeDialog"
          :width="
            $vuetify.breakpoint.name == 'xs'
              ? '100vw'
              : $vuetify.breakpoint.name == 'sm'
              ? '50vw'
              : $vuetify.breakpoint.name == 'md'
              ? '40vw'
              : $vuetify.breakpoint.name == 'lg'
              ? '30vw'
              : '20vw'
          "
        >
          <v-card>
            <v-layout wrap justify-center pa-2>
              <v-flex xs12 pt-2>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 16px;
                    color: #000;
                  "
                >
                  Scan qrcode to visit this page
                </span>
              </v-flex>
              <v-flex xs12>
                <vue-qrcode
                  :value="
                    'https://www.styloop.in/shops/' +
                    appUser._id +
                    '/' +
                    appUser.organization
                  "
                  :width="200"
                />
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
        <v-layout wrap justify-start>
          <v-flex xs6 sm3>
            <v-card flat rounded="xl" color="#F5F7FC" outlined>
              <router-link to="/Inventory">
                <v-layout pa-2 pl-4 py-4 wrap justify-start>
                  <v-flex xs4 pt-4>
                    <v-img
                      src="./../../../assets/dailyvisitors.png"
                      height="30px"
                      contain
                      alt="Daily Visitors"
                    ></v-img>
                  </v-flex>
                  <v-flex pt-4 xs12 text-left>
                    <span
                      style="
                        font-family: opensanssemibold;
                        font-size: 12px;
                        color: #6e6b6b;
                      "
                    >
                      Total Products
                    </span>
                  </v-flex>
                  <v-flex
                    xs12
                    text-left
                    align-self-center
                    v-if="storage.totalProducts"
                  >
                    <span
                      style="
                        font-family: opensansbold;
                        font-size: 18px;
                        color: #000000;
                      "
                    >
                      {{ storage.totalProducts }}
                    </span>
                    <!-- <span
                    v-if="storage.totalProducts"
                    class="pl-1"
                    style="
                      font-family: opensansbold;
                      font-size: 12px;
                      color: #3dc131;
                    "
                  >
                    <v-icon color="#3DC131" size="14">mdi-arrow-up</v-icon>
                    {{ conversion(storage.totalProducts) }}%
                  </span> -->
                    <!-- <span
                    v-else
                    class="pl-1"
                    style="
                      font-family: opensansbold;
                      font-size: 12px;
                      color: #30B868;
                    "
                  >
                    <v-icon color="#30B868" size="14">mdi-arrow-down</v-icon>
                    {{ conversion(storage.dailyvisitor.percentage) }}%
                  </span> -->
                  </v-flex>
                </v-layout>
              </router-link>
            </v-card>
          </v-flex>
          <v-flex xs6 sm3 pl-2>
            <v-card flat rounded="xl" color="#F5F7FC" outlined>
              <router-link to="/OrderReports">
                <v-layout pa-2 pl-4 py-4 wrap justify-start>
                  <v-flex xs4 pt-4>
                    <v-img
                      src="./../../../assets/orders.png"
                      height="32px"
                      contain
                      alt="Orders"
                    ></v-img>
                  </v-flex>
                  <v-flex pt-4 xs12 text-left>
                    <span
                      style="
                        font-family: opensanssemibold;
                        font-size: 12px;
                        color: #6e6b6b;
                      "
                    >
                      Total Orders
                    </span>
                  </v-flex>
                  <v-flex xs12 text-left align-self-center>
                    <span
                      style="
                        font-family: opensansbold;
                        font-size: 18px;
                        color: #000000;
                      "
                    >
                      {{ storage.totalOrders }}
                    </span>
                  </v-flex>
                </v-layout>
              </router-link>
            </v-card>
          </v-flex>
          <v-flex xs6 sm3 pt-2 pt-sm-0 pl-sm-2>
            <v-card flat rounded="xl" color="#F5F7FC" outlined>
              <router-link to="/PendingOrders">
                <v-layout pa-2 pl-4 py-4 wrap justify-start>
                  <v-flex xs4 pt-4>
                    <v-img
                      src="./../../../assets/avgorders.png"
                      height="32px"
                      contain
                      alt="Average Orders"
                    ></v-img>
                  </v-flex>
                  <v-flex pt-4 xs12 text-left>
                    <span
                      style="
                        font-family: opensanssemibold;
                        font-size: 12px;
                        color: #6e6b6b;
                      "
                    >
                      Pending Orders
                    </span>
                  </v-flex>
                  <v-flex
                    xs12
                    text-left
                    align-self-center
                    v-if="storage.pendingOrders"
                  >
                    <span
                      style="
                        font-family: opensansbold;
                        font-size: 18px;
                        color: #000000;
                      "
                    >
                      {{ storage.pendingOrders }}
                    </span>
                    <!-- <span
                    v-if="storage.avgorder.positive"
                    class="pl-1"
                    style="
                      font-family: opensansbold;
                      font-size: 12px;
                      color: #3dc131;
                    "
                  >
                    <v-icon color="#3DC131" size="14">mdi-arrow-up</v-icon>
                    {{ conversion(storage.avgorder.percentage) }}%
                  </span>
                  <span
                    v-else
                    class="pl-1"
                    style="
                      font-family: opensansbold;
                      font-size: 12px;
                      color: #30B868;
                    "
                  >
                    <v-icon color="#30B868" size="14">mdi-arrow-down</v-icon>
                    {{ conversion(storage.avgorder.percentage) }}%
                  </span> -->
                  </v-flex>
                </v-layout>
              </router-link>
            </v-card>
          </v-flex>
          <!-- <v-flex xs6 sm3 pl-2 pt-2 pt-sm-0>
            <v-card flat rounded="xl" @click="showQRCodeDialog = true" color="#F5F7FC" outlined>
              <v-layout pa-2 py-4 wrap justify-start>
                <v-flex  style="cursor:pointer"  pt-4 text-left align-self-center>
                  <v-icon size="34" color="#000000">mdi-qrcode</v-icon>
                </v-flex>
                <v-flex xs12 pt-4 pb-6 text-left>
                  <span
                    style="
                      font-family: opensanssemibold;
                      font-size: 12px;
                      color: #6e6b6b;
                      line-spacing:0px!important;
                      line-height:0px!important;

                    "
                  >
                    Click to view QR
                  </span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex> -->
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueQrcode from "vue-qrcode";
export default {
  components: {
    VueQrcode,
  },
  props: ["storage"],
  data() {
    return {
      displayBTN: false,
      showQRCodeDialog: false,
    };
  },

  computed: {
    appUser() {
      return this.$store.state.userData;
    },
  },
  methods: {
    conversion(item) {
      return Math.round(item * 100) / 100;
    },
  },
};
</script>