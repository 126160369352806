<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-layout pt-2 wrap justify-start>
      <v-flex xs12 text-left>
        <span
          style="font-family: opensansbold; font-size: 14px; color: #808080"
        >
          Total Sales
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-end>
      <v-flex xs8 sm9 md9 lg9 xl10 text-left align-self-end>
        <v-layout wrap justify-center>
          <v-flex xs7 sm6>
            <span
              style="font-family: opensansbold; font-size: 22px; color: #1c1c1c"
            >
              ₹ {{ graphData.totalsale.toFixed(2) }}
            </span>
          </v-flex>
          <v-flex xs5 sm6 text-left align-self-end>
            <span
              style="font-family: opensansbold; font-size: 15px; color: #3dc131"
            >
              Transactions:
            </span>
            <span
              style="font-family: opensansbold; font-size: 15px; color: #3dc131"
            >
              {{ graphData.transactions }}
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs4 sm3 md3 lg3 xl2 text-right>
        <v-select
          outlined
          dense
          color="#f5f5f529"
          placeholder="Sort"
          v-model="sortItem"
          :items="sortItems"
          item-text="name"
          item-value="value"
          item-color="#70707029"
          hide-details
          class="analyticsSelector rounded-lg"
          v-on:input="getData"
        >
          <template v-slot:item="{ item }">
            <span
              class="text-left"
              style="font-family: opensansbold; font-size: 18px; color: #041c4b"
            >
              {{ item.name }}
            </span>
          </template>
          <template v-slot:selection="{ item }">
            <span
              style="font-family: opensansbold; font-size: 18px; color: #041c4b"
            >
              {{ item.name }}
            </span>
          </template>
        </v-select>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex v-if="flag">
        <PageLoader v-bind:storage="appLoading" v-bind:contain="true" />
        <apexchart
          width="100%"
          height="300px"
          type="line"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      ServerError: false,
      flag: false,
      sortItems: [
        { name: "2023", value: "2023" },
        { name: "2024", value: "2024" },
      ],
      sortItem: "2024",
      xaxis: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      series: [
        // {
        //   data:[]
        // },
        // {
        //   data:[]
        // }
      ],
      graphData: {
        graph: [],
      },
    };
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    chartOptions: function () {
      var that = this;
      return {
        chart: {
          id: "SellerAnalytics",
          yaxis: {
            labels: {
              formatter: function (value) {
                return value.toFixed(2);
              },
            },
          },
          toolbar: {
            show: false,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
          sparkline: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(2);
            },
          },
        },

        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          tooltip: {
            enabled: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
            colorStops: [
              {
                offset: 0,
                color: "#069EFC",
                opacity: 1,
              },
              {
                offset: 100,
                color: "#14F4C9",
                opacity: 1,
              },
            ],
          },
        },
        dataLabels: {
          enabled: false, // Hide data labels
        },
        tooltip: {
          custom: function ({ series, dataPointIndex }) {
            var content =
              `<div data-v-5adcc081="" class="flex xs12" style="background-color:transparent">
                <div data-v-5adcc081="" class="rounded-t-lg v-card v-card--flat v-sheet  rounded-0" style="background-color: rgb(17, 39, 84);">
                  <div data-v-5adcc081="" class="layout wrap justify-start py-1 px-2">
                    <div data-v-5adcc081="" class="flex xs8 xl9 text-left">
                      <span data-v-5adcc081="" style="font-family: opensanslight; font-size: 14px; color: rgb(201, 201, 201);"> Total Sales </span>
                    </div>
                    <div data-v-5adcc081="" class="flex xs4 xl3 text-left">
                      <span data-v-5adcc081="" style="font-family: opensanslight; font-size: 14px; color: rgb(201, 201, 201);">` +
              that.xaxis[dataPointIndex] +
              `</span>
                    </div>
                  </div>
                </div>
                <div data-v-5adcc081="" class="rounded-b-lg v-card v-card--flat v-sheet theme--light rounded-0" style="background-color: rgb(4, 28, 75); ">
                  <div data-v-5adcc081="" class="layout wrap justify-center px-2">
                    <div data-v-5adcc081="" class="flex xs12 text-left">
                      <span data-v-5adcc081="" style="font-family: opensansbold; font-size: 19px; color: rgb(255, 255, 255);">₹` +
              series[0][dataPointIndex].toFixed(2) +
              `</span>
                    </div>
                    <div data-v-5adcc081="" class="flex xs12 text-left">
                      <span data-v-5adcc081="" style="font-family: opensansbold; font-size: 12px; color: rgb(61, 193, 49);">Transactions: ` +
              that.graphData.graph[1].data[dataPointIndex] +
              `</span>
                    </div>
                  </div>
                </div>
              </div>`;
            return content;
          },
        },
      };
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/order/seller/graph",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          // id: this.$route.params.id,
          year: this.sortItem,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.graphData = response.data.data;
            // if (this.graphData) {
            //   this.series.push(this.graphData.graph[0]);
            // }
            for (var i = 0; i < this.graphData.graph.length; i++) {
            this.series=[{data:this.graphData.graph[0].data}]
          }
            this.flag = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>