<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout pt-3 wrap justify-start>
          <v-flex xs5 sm3 md3 lg2 xl2 pl-4>
            <v-layout wrap justify-center>
              <v-flex xs3 sm2 text-left align-self-center>
                <v-img
                  src="./../../../assets/greenlogo.png"
                  contain
                  height="14px"
                  alt="STYLOOP"
                ></v-img>
              </v-flex>
              <v-flex xs9 sm10 text-left pl-2 align-self-center>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 16px;
                    color: #1c1c1c;
                  "
                >
                  Analytics
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 lg3 align-self-center pt-2 pt-sm-0>
            <v-layout wrap justify-center>
              <v-flex xs3>
                <router-link to="/">
                  <span
                    style="
                      font-family: opensansregular;
                      font-size: 14px;
                      color: #9d9d9d;
                    "
                  >
                    Home
                  </span>
                </router-link>
              </v-flex>
              <v-flex xs3>
                <router-link to="/Inventory">
                  <span
                    style="
                      font-family: opensansregular;
                      font-size: 14px;
                      color: #9d9d9d;
                    "
                  >
                    Products
                  </span>
                </router-link>
              </v-flex>
              <v-flex xs3>
                <router-link to="/SoldProducts">
                  <span
                    style="
                      font-family: opensansregular;
                      font-size: 14px;
                      color: #9d9d9d;
                    "
                  >
                    Sold
                  </span>
                </router-link>
              </v-flex>
              <v-flex xs3>
                <router-link to="/ShippingOrders">
                  <span
                    style="
                      font-family: opensansregular;
                      font-size: 14px;
                      color: #9d9d9d;
                    "
                  >
                    Shipped
                  </span>
                </router-link>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout px-2 px-sm-4 wrap justify-center>
          <v-flex xs12 md12 lg12 xl12>
            <v-layout wrap justify-center>
              <v-flex xs12 pt-6 py-2>
                <Statistics v-bind:storage="statistics" />
              </v-flex>
              <v-flex xs12 py-2>
                <TotalSales />
              </v-flex>
              <v-flex xs12 py-2>
                <TopSellingProducts v-bind:storage="topSelling" />
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- <v-flex xs12 md5 lg5 xl5 px-sm-2>
            <PreviousOrders v-bind:storage="previousOrders" />
          </v-flex> -->
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
// import PreviousOrders from "./previousOrders";
import Statistics from "./statistics";
import TotalSales from "./totalSales";
import TopSellingProducts from "./topSellingProducts";
export default {
  components: {
    // PreviousOrders,
    Statistics,
    TotalSales,
    TopSellingProducts,
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      ServerError: false,
      msg: null,
      statistics: {},
      previousOrders: [],
      topSelling: [],
      graphdata: {},
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/SellerDashBoard",
        headers: {
          token: localStorage.getItem("token"),
        },
        // params: {
        //   id: this.$route.params.id,
        // },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.statistics = response.data;
            this.topSelling = response.data.recentOrders;
            // this.previousOrders = response.data.data.last10orders;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>